<template>
  <tr @click='highlight' class='short-cell'>
    <td>
      <button class='px-2 py-2' @click='toggleDetails'>
        <component :is='toggleIcon' class='h-4 mb-1 inline-block' />
        {{ipo.name}}
      </button>
    </td>
    <td class='border-l text-right'>{{companyCompetitionRate}}</td>
    <td class='border-l text-right'>{{ipo.details['의무보유확약']}}</td>
    <td class='border-l text-right'>{{competitionRateNumber}}</td>
    <td class='border-l text-right'>{{ipo.listing_date}}</td>
    <td class='border-l text-right'>{{formattedAllocatedPrice}}</td>
    <td class='border-l text-right'>{{formattedClosePrice}}</td>
    <td class='border-l text-right'>{{disparateRatio}}</td>
    <td class='border-l text-right'>{{numberStyle(ipo.allocated_quantity)}}</td>
    <td class='border-l text-right'>{{numberStyle(ipo.allocated_amount)}}</td>
    <td class='border-l text-right'>{{allocatedRate}}</td>
    <td class='border-l text-right'>{{numberStyle(positionQuantity)}}</td>
    <td class='border-l text-right'>{{numberStyle(positionCloseValue)}}</td>
    <td class='border-l text-right'>{{numberStyle(averageSellingPrice)}}</td>
    <td class='border-l text-right'>{{numberStyle(recoveryGainLoss)}}</td>
    <td class='border-l text-right'>{{recovery}}</td>
    <td class='border-l text-right'>{{earningsRatio}}</td>
    <td class='border-l text-right'>{{ipo.lockup_expiration_date}}</td>
    <td class='border-l text-right'></td>
    <td class='border-l text-left'>
      <div class='inline-block w-32 whitespace-normal'>
        <a class='hover:underline' :href='announcementLink' target='_blank'>
          {{announcementLinkTitle}}
          <external-link-icon class='h-4 mb-1 inline-block' />
        </a>
      </div>
    </td>
  </tr>
</template>

<script>
import { ChevronDownIcon, ChevronRightIcon, ExternalLinkIcon } from '@vue-hero-icons/outline'
import groupBy from 'lodash/groupBy'

export default {
  name: 'IpoPortfolioRow',
  props: [
    'ipo',
    'showGroupDetails',
  ],
  components: {
    ChevronDownIcon,
    ChevronRightIcon,
    ExternalLinkIcon,
  },
  data () {
    return {
      showDetails: false,
    }
  },
  watch: {
    'showGroupDetails': {
      handler: function (newVal) {
        this.showDetails = newVal
      },
      immediate: true
    },
  },
  computed: {
    toggleIcon () {
      return this.showDetails ? 'chevron-down-icon' : 'chevron-right-icon'
    },
    groupByAccountType () {
      return groupBy(this.ipo.portfolios, 'account_type')
    },
    allocatedPrice () {
      return this.ipo.details['확정공모가'] ? this.ipo.details['확정공모가'] : 0
    },
    formattedAllocatedPrice () {
      return this.allocatedPrice > 0 ? `${this.numberStyle(this.ipo.details['확정공모가'])} 원` : ''
    },
    formattedClosePrice () {
      return this.ipo.close_price > 0 ? `${this.numberStyle(this.ipo.close_price)} 원` : ''
    },
    disparateRatio () {
      return this.allocatedPrice > 0 ? `${((this.ipo.close_price / this.allocatedPrice - 1) * 100).toFixed(1)} %` : ''
    },
    baseAllocatedQuantity () {
      return this.ipo.details['기관배정주식수'] ? parseFloat(this.ipo.details['기관배정주식수']) : 0
    },
    allocatedRate () {
      return (this.ipo.allocated_quantity === 0 || this.baseAllocatedQuantity ===  0) ? '' : `${(this.ipo.allocated_quantity / this.baseAllocatedQuantity * 100).toFixed(3)} %`
    },
    positionQuantity () {
      return this.ipo.portfolios.reduce((sum, port) => sum + port.position_quantity, 0)
    },
    positionCloseValue () {
      return this.ipo.portfolios.reduce((sum, port) => sum + port.position_close_value, 0)
    },
    averageSellingPrice () {
      let sum = 0
      let count = 0
      this.ipo.portfolios.forEach(row => {
        if (row.average_selling_price > 0) {
          sum += row.average_selling_price
          count += 1
        }
      })

      return count === 0 ? 0 : parseFloat(sum / count).toFixed(0)
    },
    recoveryGainLoss () {
      if (this.ipo.allocated_quantity === 0) {
        return 0
      } else {
        return this.averageSellingPrice !== 0 ? (this.averageSellingPrice - this.allocatedPrice) * (this.ipo.allocated_quantity - this.positionQuantity) : 0
      }
    },
    recovery () {
      return this.ipo.allocated_quantity === 0 ? '' : `${((this.ipo.allocated_quantity - this.positionQuantity) / this.ipo.allocated_quantity * 100).toFixed(1)} %`
    },
    earningsRatio () {
      return (this.recoveryGainLoss === 0 || this.ipo.allocated_quantity === 0) ? '' : `${(this.recoveryGainLoss / this.ipo.allocated_amount * 100).toFixed(1)} %`
    },
    competitionRate () {
      if (this.ipo.details['청약경쟁률']) {
        return (this.ipo.details['청약경쟁률']).includes('비례') ? this.ipo.details['청약경쟁률'].split('(비례')[0] : this.ipo.details['청약경쟁률']
      } else {
        return ''
      }
    },
    competitionRateNumber () {
      if (this.competitionRate) {
        return (Number(parseFloat(this.competitionRate.split(':')).toFixed(2)).toLocaleString()).toString() + ':1'
      } else {
        return ''
      }
    },
    companyCompetitionRate () {
      if (this.ipo.details['기관경쟁률']) {
        return (Number(parseFloat(this.ipo.details['기관경쟁률'].split(':')[0].replace(/,/g , '')).toFixed(2)).toLocaleString()).toString() + ':1'
      } else {
        return 0
      }
    },
    announcementLinkTitle () {
      return this.ipo.details['증권신고서'] ? this.ipo.name : ''
    },
    announcementLink () {
      return this.ipo.details['증권신고서'] ? this.ipo.details['증권신고서'] : ''
    },
  },
  methods: {
    numberStyle (number) {
      return !number ? '' : Number(number).toLocaleString()
    },
    toggleDetails () {
      this.showDetails = !this.showDetails
      this.$emit('update:toggleDetails', this.showDetails)
    },
    highlight () {
      this.$emit('select-row', this.ipo.id)
    },
  },
}
</script>
