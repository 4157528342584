<template>
  <tbody>
    <ipo-portfolio-row
      :ipo='ipo'
      :show-group-details='showDetails'
      :key='ipo.id'
      @select-row='selectRow'
      v-bind:toggle-details.sync='showDetails' />
      <ipo-portfolio-details
        v-for='detail in ipo.portfolios'
        :ipo='ipo'
        :detail='detail'
        :allocated-price='ipo.details["확정공모가"]'
        :key='`${ipo.name}-${detail.account_type}`'
        :is-visible='showDetails' />
  </tbody>
</template>

<script>
import IpoPortfolioRow     from '@/views/ipo/IpoPortfolioRow.vue'
import IpoPortfolioDetails from '@/views/ipo/IpoPortfolioDetails.vue'

export default {
  name: 'IpoPortfolioGroup',
  components: {
    IpoPortfolioRow,
    IpoPortfolioDetails,
  },
  props: [
    'ipo',
    'showAll',
  ],
  data () {
    return {
      showDetails: false,
    }
  },
  watch: {
    'showAll': {
      handler: function (newVal) {
        this.showDetails = newVal
      },
      immediate: true
    },
  },
  methods: {
    selectRow () {
      this.$emit('select-row', this.ipo.id)
    },
  },
}
</script>
